<script setup lang="ts">
const { easyFunConfig } = useDialog()
const countdown = ref(10)
function goEasyFun() {
  window.location.href = 'https://www.easyfun.gg'
}
onMounted(() => {
  const timer = setInterval(() => {
    if (countdown.value > 1) {
      countdown.value -= 1
    }
    else {
      clearInterval(timer)
      goEasyFun()
    }
  }, 1000)
})
</script>

<template>
  <LdDialog v-model="easyFunConfig.visible" class="rd-16px lt-md:w-full" :close-by-mask="false">
    <div class="max-w-500px w-full overflow-hidden rd-t-8px bg-#25252d pb-14px color-#fff lt-md:mx-auto lt-md:w-96% md:rd-b-8px">
      <div class="min-h-90px flex items-center justify-start" bg="cover center no-repeat" :style="{ 'background-image': `url(${getPublicImg('easyfun/dialog-header.png')})` }">
        <span class="max-w-300px flex-wrap py-10px pl-30px text-20px color-#f6fdd8b2 font-700 lh-24px">{{ $t('dialog_easyfun.brand_upgrade') }}</span>
      </div>
      <div class="p-24px">
        <p class="text-20px font-bold">
          {{ $t('dialog_easyfun.dear_users') }}
        </p>
        <p class="mt-15px text-16px font-400 line-height-24px op-50">
          {{ $t('dialog_easyfun.to_provide') }}{{ $t('dialog_easyfun.please_accept') }}{{ $t('dialog_easyfun.we_hope') }}
        </p>
      </div>
      <button class="ld-button mx-auto mt-6px flex bg-#ccff00 py-12px" @click="goEasyFun">
        {{ $t('dialog_easyfun.go_to') }}
      </button>
      <p class="mt-8px text-center text-12px op-30">
        {{ $t('dialog_easyfun.jump_to', { XX: countdown }) }}
      </p>
    </div>
  </LdDialog>
</template>
