type LdDialog<T = Record<string, any>> = T & {
  visible: boolean
  /** 确认 */
  confirm?: () => void
  /** 取消 */
  cancel?: () => void
  /** 打开前的钩子，可以修改配置 */
  beforeOpen?: (config: LdDialog<T>) => void
  /** 打开后的钩子，可以修改配置 */
  afterOpen?: (config: LdDialog<T>) => void
  /** 关闭前的钩子，可以修改配置 */
  beforeClose?: (config: LdDialog<T>) => void
  /** 关闭后的钩子，可以修改配置 */
  afterClose?: (config: LdDialog<T>) => void
}

export function useDialog() {
  /** 分享弹窗 */
  const shareConfig = useState<LdDialog>('SHARE-DIALOG', () => ({
    visible: false,
  }))

  /** EasyFun 弹窗 */
  const easyFunConfig = useState<LdDialog>('EASYFUN-DIALOG', () => ({
    visible: false,
  }))

  return {
    shareConfig,
    openshareDialog: openDialog(shareConfig),
    closeshareDialog: closeDialog(shareConfig),

    easyFunConfig,
    openEasyFunDialog: openDialog(easyFunConfig),
  }
}

/** 通用封装打开方法 */
function openDialog<T>(defConf: Ref<LdDialog<T>>) {
  return (newConf?: Omit<LdDialog<T>, 'visible'>) => {
    loMerge(defConf.value, newConf)
    defConf.value.beforeOpen?.(defConf.value)
    defConf.value.visible = true
    defConf.value.afterOpen?.(defConf.value)
  }
}
/** 通用封装关闭方法 */
function closeDialog<T>(defConf: Ref<LdDialog<T>>) {
  return (newConf?: Omit<LdDialog<T>, 'visible'>) => {
    loMerge(defConf.value, newConf)
    defConf.value.beforeClose?.(defConf.value)
    defConf.value.visible = false
    defConf.value.afterClose?.(defConf.value)
  }
}
